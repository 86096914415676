import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import * as config from "../../utils/apiVersion";
import { startLoading, stopLoading } from "../feature";
import { errorToastSelector, successToastSelector } from "../selector";
import { buildQueryString } from "../../helpers";
import { getUser } from "./user";

export const getBalance = createAsyncThunk(
  "commonServices/balances",
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const conf = config.getConfig(payload.tradeType);
    console.log("payload", payload);
    try {
      dispatch(startLoading());
      // ?${buildQueryString(payload)}
      let url = payload.search
        ? `account/balances?search=
      ${JSON.stringify(payload.search)}`
        : `account/balances`;
      let res = await API.get(conf)(url);
      dispatch(stopLoading());
      return { list: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getCurrencies = createAsyncThunk(
  "commonServices/getCurrencies",
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const conf = config.getConfig(payload.tradeType);
    delete payload.tradeType;
    try {
      dispatch(startLoading());
      let url = ``;
      if (payload?.id) {
        url = `public/currencies/${payload.id}`;
      } else if (payload?.type) {
        url = `public/currencies?type=${payload?.type}`;
      } else {
        url = `public/currencies`;
      }
      let res = await API.get(conf)(url);
      dispatch(stopLoading());
      return { list: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getCurrencyDetails = createAsyncThunk(
  "commonServices/getCurrencyDetails",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = "";
      if (payload?.id) {
        url = `home/overview/${payload.id}`;
      } else {
        url = `home/list`;
      }
      let res = await API.get(config.peatio)(url);
      dispatch(stopLoading());
      return { status: true, list: res };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getGraphData = createAsyncThunk(
  "commonServices/getGraphData",
  async ({ id, ...payload }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `home/overview/graph/${id}?${buildQueryString(payload)}`;
      let res = await API.get(config.peatio)(url);
      dispatch(stopLoading());
      return { status: true, list: res };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getGainersLosers = createAsyncThunk(
  "commonServices/getGainersLosers",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `home/list/movers`;
      let res = await API.get(config.peatio)(url);
      dispatch(stopLoading());
      return { status: true, data: res };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getSingleCurrencyDetails = createAsyncThunk(
  "commonServices/getSingleCurrencyDetails",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      let id = payload.id;
      delete payload.id;
      dispatch(startLoading());
      let url = `home/overview/${id}?${buildQueryString(payload)}`;
      let res = await API.get(config.peatio)(url);
      dispatch(stopLoading());
      return res;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getBalanceHistory = createAsyncThunk(
  "commonServices/getBalanceHistory",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/account/BalanceHistory?${buildQueryString(payload)}`;
      let res = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading());
      return {
        data: res.data,
        total: res.headers.total,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const generateQrCode = createAsyncThunk(
  "commonServices/generateQrCode",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `resource/otp/generate_qrcode`;
      let res = await API.post(config.barong)(url);
      dispatch(stopLoading());
      return { qrCode: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const enableTwoFactorAuth = createAsyncThunk(
  "commonServices/enableTwoFactorAuth",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `resource/otp/enable`;
      let res = await API.post(config.barong)(url, payload);
      dispatch(getUser());
      dispatch(stopLoading());
      dispatch(successToastSelector({ message: "2FA Enabled Successfully" }));
      return { code: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const disableTwoFactorAuth = createAsyncThunk(
  "commonServices/disableTwoFactorAuth",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `resource/otp/disable`;
      let res = await API.post(config.barong)(url, payload);
      dispatch(getUser());
      dispatch(stopLoading());
      dispatch(successToastSelector({ message: "2FA Disabled Successfully" }));
      return { code: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const getPortfolioPrice = createAsyncThunk(
  "commonServices/getPortfolioPrice",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/account/portfolio_price`;
      let res = await API.get(config.peatio)(url);
      dispatch(stopLoading());
      return res;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getPortfolio = createAsyncThunk(
  "commonServices/getPortfolio",
  async (payload, { rejectWithValue, dispatch }) => {
    console.log("payload", payload);
    try {
      dispatch(startLoading());
      let url = `/account/update/portfolio?${buildQueryString(payload)}`;
      let res = await API.get(config.peatio)(url);
      dispatch(stopLoading());
      return res;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getPortfolioCurrency = createAsyncThunk(
  "commonServices/getPortfolioCurrency",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/account/members/me`;
      let res = await API.get(config.peatio)(url);
      dispatch(stopLoading());
      return res;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getConvertMNG = createAsyncThunk(
  "commonServices/getConvertMNG",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/account/small_asset/list`;
      let res = await API.get(config.peatio)(url);
      dispatch(stopLoading());
      return res;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getConvertCurrencyDetail = createAsyncThunk(
  "commonServices/getConvertCurrencyDetail",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/account/currency_detail`;
      let res = await API.get(config.peatio)(url);
      dispatch(stopLoading());
      return res;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const convertValueApi = createAsyncThunk(
  "commonServices/convertValueApi",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/account/swap_small_asset`;
      let res = await API.post(config.peatio)(url, payload);
      dispatch(successToastSelector({ message: "Converted Successfully" }));
      dispatch(stopLoading());
      return { qrCode: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);
