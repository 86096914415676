import { createSlice } from "@reduxjs/toolkit";
import {
  getConvertHistory,
  getDepositHistory,
  getMarketList,
  getWithdrawHistory,
} from "../../services";
export const transHistoriesSlice = createSlice({
  name: "transHistories",
  initialState: {
    withdrawHistory: [],
    withdrawHistoryTotal: 0,
    depositHistory: [],
    depositHistoryTotal: 0,
    convertHistory: [],
    convertTotal: 0,
    marketPairList: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWithdrawHistory.pending, (state) => {})
      .addCase(getWithdrawHistory.fulfilled, (state, action) => {
        state.withdrawHistory = action.payload.list;
        state.withdrawHistoryTotal = action.payload.total ?? 0;
      })
      .addCase(getWithdrawHistory.rejected, (state, action) => {})

      .addCase(getDepositHistory.pending, (state) => {})
      .addCase(getDepositHistory.fulfilled, (state, action) => {
        state.depositHistory = action.payload.list;
        state.depositHistoryTotal = action.payload.total ?? 0;
      })
      .addCase(getDepositHistory.rejected, (state, action) => {})

      .addCase(getConvertHistory.pending, (state) => {})
      .addCase(getConvertHistory.fulfilled, (state, action) => {
        state.convertHistory = action.payload.list;
        state.convertTotal = action.payload.total ?? 0;
      })
      .addCase(getConvertHistory.rejected, (state, action) => {})

      .addCase(getMarketList.pending, (state) => {})
      .addCase(getMarketList.fulfilled, (state, action) => {
        state.marketPairList = action.payload.list;
      })
      .addCase(getMarketList.rejected, (state, action) => {});
  },
});

export const {} = transHistoriesSlice.actions;
export default transHistoriesSlice.reducer;
