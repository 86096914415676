import { showAlert } from "../feature";
export const successToastSelector = (e) => {
  return showAlert({
    message: [e.message],
    type: "success",
  });
};

export const errorToastSelector = (e) => {
  return showAlert({
    message: [e.message],
    code: e?.code ? e?.code : 422,
    type: "error",
  });
};
