import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import CommonButton from "../../Ui/button/CommonButton";
import style from "./style.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { getBalance } from "../../../redux/services";
import {
  addBeneficiaryName,
  depositWithdrawDropdownType,
  fieldType,
  sendReceiveType,
} from "../../../staticObjects";
import CommonRenderFields from "../../Ui/commonRenderFields";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addBeneficiarySchema } from "../../../Schema";
import CommonAssetNetworkList from "./commonAssetNetworkList";
import { filterCurrWithNetwork } from "../../../helpers";
import CommonModal from "../../CommonModal";

const AddAddress = (props) => {
  const dispatch = useDispatch();
  const { currenciesList } = useSelector((state) => state.commonApiData);
  const [newAddress, setNewAddress] = useState(false);
  const [currentObj, setCurrentObj] = useState({});
  const [currentNetwork, setCurrentNetwork] = useState({});
  const { asset, network } = depositWithdrawDropdownType;
  const { normalInput } = fieldType;
  const { addressLabel, address, code } = addBeneficiaryName;
  const { isOpenAddressModal, callFrom, closeAddressModal } = props;
  const isFromSendModal = callFrom === "sendModal";

  const filterCurrencyByType = (list) => {
    let filterArr = list?.filter((val, idx) => val?.currency_type === "coin");
    if (filterArr.length) {
      let newArray = filterCurrWithNetwork(currenciesList, list);
      setNewAddress(true);
      setCurrentObj({ ...newArray[0] });
    }
  };

  const actionsFun = async () => {
    let res = await dispatch(getBalance());
    if (res.payload.status) {
      filterCurrencyByType(res.payload.list);
    }
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isValid: formIsvalid },
    reset,
  } = useForm({
    resolver: yupResolver(addBeneficiarySchema),
  });

  const closeModal = () => {
    if (isFromSendModal) {
      closeAddressModal();
    }
    setNewAddress(false);
  };

  const onSubmit = (data) => {
    if (formIsvalid) {
      props.cbFun({
        ...data,
        currencyObj: currentObj,
        currentNetwork: currentNetwork,
        onModalClose: () => closeModal(),
      });
    }
  };

  const cbFunAssetNetwrok = (obj, type) => {
    if (type === asset) {
      if (obj.currency !== currentObj.currency) {
        setCurrentObj({ ...obj });
      }
    } else if (type === network) {
      if (obj?.blockchain_key !== currentNetwork.blockchain_key) {
        setCurrentNetwork({ ...obj });
      }
    }
  };

  useEffect(() => {
    if (isOpenAddressModal) {
      actionsFun();
    }
  }, [isOpenAddressModal]);

  useEffect(() => {
    reset();
  }, [newAddress]);

  return (
    <div>
      {!isFromSendModal && (
        <CommonButton title="Add New Address" onClick={actionsFun} />
      )}
      <CommonModal isOpen={newAddress} onCancel={closeModal}>
        <h2 className="titleModal">Add Address</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={style.addAdress}>
            <CommonRenderFields
              errors={errors}
              control={control}
              name={addressLabel}
              placeholder={"4-30 characters e.g Amy's KaziX"}
              label={"Address Label"}
              type={normalInput}
            />
            <CommonAssetNetworkList
              className={`${style.pd0}`}
              cbFun={cbFunAssetNetwrok}
              balanceObj={currentObj}
              type={sendReceiveType.withdraw}
              isAddAddressModal={true}
            />
            <CommonRenderFields
              errors={errors}
              control={control}
              name={address}
              placeholder={"Enter Address Here"}
              label={"Address"}
              type={normalInput}
            />
            <div className="mt10">
              <CommonRenderFields
                errors={errors}
                control={control}
                name={code}
                placeholder={"Enter code"}
                label={"Enter 2FA code"}
                type={normalInput}
              />
            </div>
            <CommonButton title="Save" htmlType="submit" />
          </div>
        </form>
      </CommonModal>
    </div>
  );
};

export default AddAddress;
