import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RightOutlined } from "@ant-design/icons";
import { uppercase } from "../../../helpers";
import {
  depositWithdrawDropdownType,
  sendReceiveType,
} from "../../../staticObjects";
import ListLikeDropdown from "../../Common/listLikeDropdown";
import style from "./style.module.scss";
import {
  fetchBeneficiary,
  getAddress,
} from "../../../redux/services/sendReceive";
import DownArrow from "../../Assets/Images/downarrowdark.svg";
const initDivs = {
  assetDiv: false,
  networkDiv: false,
};
const CommonAssetNetworkList = (props) => {
  const dispatch = useDispatch();
  const { currenciesList } = useSelector((state) => state.commonApiData);
  const [divStatus, setDivStatus] = useState(initDivs);
  const [currentCurrencyObj, setCurrentCurrencyObj] = useState({});
  const [currentNetwork, setCurrentNetwork] = useState({});
  const { asset, network } = depositWithdrawDropdownType;
  const [lastAction, setLastAction] = useState(asset);

  const { convertIcon, cbFun, className, type, isAddAddressModal, staticData } = props;
  const { assetDiv, networkDiv } = divStatus;
  const currObj = props.balanceObj;

  useEffect(() => {
    let curr = filterSelectCurrObj(currObj?.currency);
    if (curr !== undefined && Object.keys(curr).length > 0) {
      setCurrentCurrencyObj(curr);
      setCurrentNetwork({ ...curr?.networks[0] });
      cbFun({ ...curr?.networks[0] }, network);
    }
  }, [currenciesList, currObj]);

  useEffect(() => {
    if (
      currentCurrencyObj !== undefined &&
      Object.keys(currentCurrencyObj).length &&
      Object.keys(currentNetwork).length
    ) {
      if (type === sendReceiveType.deposit) {
        dispatch(
          getAddress({
            id: currentCurrencyObj?.id,
            blockchain_key: currentNetwork?.blockchain_key,
          })
        );
      } else if (type === sendReceiveType.withdraw) {
        if (!isAddAddressModal) {
          if (lastAction === asset) {
            dispatch(fetchBeneficiary({ coin: currentCurrencyObj?.id }));
          }
        }
      }
    }
  }, [currentCurrencyObj, currentNetwork]);

  const filterSelectCurrObj = (value) => {
    return (
      currenciesList.length > 0 &&
      currenciesList?.filter((item) => item.id === value)[0]
    );
  };
  const openCloseDivsFun = (status, type = "") => {
    let tempDiv = { ...divStatus };
    if (!status) {
      setDivStatus(initDivs);
    } else {
      if (type === asset) {
        tempDiv["assetDiv"] = true;
        tempDiv["networkDiv"] = false;
      } else if (type === network) {
        tempDiv["networkDiv"] = true;
        tempDiv["assetDiv"] = false;
      }
      setDivStatus({ ...tempDiv });
    }
  };

  const networkText = () => {
    return uppercase(currentNetwork?.blockchain_key?.split("-")[0]);
  };

  const setNetworkFun = (obj, type) => {
    if (type === network) {
      if (obj?.blockchain_key !== currentNetwork.blockchain_key) {
        setCurrentNetwork({ ...obj });
        cbFun(obj, type);
      }
      setLastAction(type);
    }
  };

  const setAssetFun = (obj, type) => {
    if (type === asset) {
      cbFun(obj, type);
      setLastAction(type);
    }
  };

  return (
    <>
      {isAddAddressModal ? (
        <div className={style.inputLike}>
          <div className={style.inputLike_outer}>
            <p>Coin</p>
            <div onClick={() => openCloseDivsFun(true, asset)}>
              {currObj?.currency_name}
              <img src={DownArrow} />
            </div>
          </div>
          <div className={style.inputLike_outer}>
            <p>Network</p>
            <div onClick={() => openCloseDivsFun(true, network)}>
              {networkText()}
              <img src={DownArrow} />
            </div>
          </div>
          <div className={`${style.cmn_drpListStyle}`}>
            {assetDiv && (
              <div
                className={`${style.positionedContent} ${style.assetDiv}  card`}
              >
                <ListLikeDropdown
                  balanceObj={currObj}
                  cbFun={setAssetFun}
                  type={asset}
                  onClick={() => openCloseDivsFun(false)}
                />
              </div>
            )}
            {networkDiv && (
              <div
                className={`${style.positionedContent} ${style.networkDiv} card`}
              >
                <ListLikeDropdown
                  type={network}
                  cbFun={setNetworkFun}
                  allNetwork={currentCurrencyObj?.networks}
                  currentNetwork={currentNetwork}
                  onClick={() => openCloseDivsFun(false)}
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className={`${style.Buy} ${className}`}>
          <div className={` ${style.Buy_btn} `}>
            <ul className={`${style.convertIcon} d-flex buyDrpDnList`}>
              <li style={{ position: "relative", border: 0 }}>
                <label>Asset</label>
                <div
                  className={`${style.dropValue} dropValue`}
                  onClick={() => openCloseDivsFun(!divStatus.assetDiv, asset)}
                >
                  <div>
                    <img
                      className={style.Buy_btn_icon}
                      src={staticData ? "https://s2.coinmarketcap.com/static/img/coins/64x64/1958.png" : currObj?.currency_icon}
                      alt="icon"
                    />
                    <span>{staticData ? "Trx" : currObj?.currency_name}</span>
                  </div>
                  <RightOutlined />
                </div>
              </li>
              <li>
                <label>Network</label>
                <div
                  className={`${style.dropValue} dropValue`}
                  onClick={() =>
                    openCloseDivsFun(!divStatus.networkDiv, network)
                  }
                >
                  <div style={{ color: "#80838e" }}>{staticData ? "TRX" : networkText()}</div>
                  <RightOutlined />
                </div>
              </li>
            </ul>
          </div>
          <div className={`${style.cmn_drpListStyle}`}>
            {assetDiv && (
              <div
                className={`${style.positionedContent} ${style.assetDiv}  card`}
              >
                <ListLikeDropdown
                  balanceObj={currObj}
                  cbFun={setAssetFun}
                  type={asset}
                  onClick={() => openCloseDivsFun(false)}
                  staticData={staticData}
                />
              </div>
            )}
            {networkDiv && (
              <div
                className={`${style.positionedContent} ${style.networkDiv} card`}
              >
                <ListLikeDropdown
                  type={network}
                  cbFun={setNetworkFun}
                  allNetwork={currentCurrencyObj?.networks}
                  currentNetwork={currentNetwork}
                  onClick={() => openCloseDivsFun(false)}
                  staticData={props?.staticData}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CommonAssetNetworkList;
