export const publicRouteObj = {
  login: "login",
  changePassword: "changePassword",
  resetPassword: "resetPassword",
  createAccount: "createAccount",
  emailVerify: "emailVerify",
  emailConfirmation: "emailConfirmation",
  identity: "identity",
  verifyIdentity: "verifyIdentity",
};

// Sidebar obj
export const sidebarRouteObj = {
  home: "home",
  port: "portfolio",
  swap: "swap",
  advTrade: "advanceTrading",
  earn: "earn",
  p2p: "p2p",
  coinListing: "coinListing",
  listedCoins: "listedCoins",
  referral: "referral",
  transactions: "transactions",
  cryoto_deposit: "cryoto_deposit",
  bank_deposit: "bank_deposit",
};

const {
  home,
  port,
  swap,
  advTrade,
  earn,
  p2p,
  coinListing,
  referral,
  listedCoins,
  transactions,
  cryoto_deposit,
  bank_deposit,
} = sidebarRouteObj;

export const defaultActiveSidebarObj = {
  [home]: ["1", "home", "homeIcon"],
  [port]: ["2", "port", "portIcon"],
  [swap]: ["3", "swap", "swapIcon"],
  [advTrade]: ["4", "advTrade", "advTradeIcon"],
  [earn]: ["5", "earn", "earnIcon"],

  // [referral]: ["5", "referral", "referralIcon"],
};

export const routeExists = [
  home,
  port,
  swap,
  advTrade,
  earn,
  p2p,
  coinListing,
  listedCoins,
  referral,
];

export const primaryRoutes = {
  verification: "verification",
  verificationCode: "verificationCode",
  overview: "overview",
  setting: "setting",
  deviceManagement: "device-management",
  accountActivity: "account-activity",
  order: "order",
  landing: "landing",
  notfication: "notfication",
  viewAll: "view-all",
  viewHistory: "view-history",
  addressManagement: "address-management",
  intro: "intro/:id",
  introWithoutId: "intro",
  rewardViewAll: "reward_view_all",
  referralViewAll: "referral_view_all",
  kycRetail: "kycRetail",
  kycCorporate: "kycCorporate",
  convertMNG: "convertMNT",
  convertHistory: "convertHistory",
  authLanding: "authLanding",
  logout: "logout",
  cryoto_deposit: "cryoto_deposit",
  bank_deposit: "bank_deposit",
};

const {
  setting,
  order,
  addressManagement,
  viewAll,
  viewHistory,
  referralViewAll,
  rewardViewAll,
  introWithoutId,
  intro,
  kycRetail,
  kycCorporate,
} = primaryRoutes;

export const headerHeadingObj = {
  [home]: "Home",
  [port]: "Portfolio",
  [swap]: "Swap",
  [advTrade]: "Advance Trading",
  [earn]: "Earn",
  [p2p]: "P2P",
  [coinListing]: "Applications",
  [listedCoins]: "Listed Coins",
  [transactions]: "Transactions History",
  [referral]: "Referral",
  [setting]: "Settings",
  [order]: "Orders",
  [addressManagement]: "Address Management",
  [viewAll]: "My Earnings",
  [viewHistory]: "History",
  [referralViewAll]: "Referral History",
  [rewardViewAll]: "Reward History",
  [kycRetail]: "Kyc",
  [kycCorporate]: "Kyc",
  [cryoto_deposit]: "Cryoto Deposit",
  [bank_deposit]: "Bank Deposit",
};

export const rangerRoutes = [advTrade, port];
export const coinListRoutes = [coinListing, introWithoutId, intro, listedCoins];
