import { Table } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomPagination from "../../customHooks/customPagination";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLoginActivity } from "../../../redux/services/securityServie";
import moment from "moment";

const LoginActivity = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(50);

  const { activityList, totalActivity } = useSelector(
    (state) => state.security
  );

  useEffect(() => {
    dispatch(
      getLoginActivity({
        limit: limit,
        page: pageNo,
        data: "session",
      })
    );
  }, [limit, pageNo]);

  const tableCol = [
    { dataIndex: "created_at", title: <p className="coins">Date</p> },
    { dataIndex: "user_agent", title: <p className="coins">Source</p> },
    { dataIndex: "action", title: <p className="coins">Action</p> },
    { dataIndex: "user_ip", title: <p className="coins">IP Address</p> },
  ];

  const datax = () => {
    let newData = [];
    activityList?.length > 0 &&
      activityList?.map((items, idx) => {
        let temp = {
          keyId: idx,
          created_at:
            moment(items?.created_at).format("DD-MM-YYYY  HH:mm") || "-",
          user_agent: items?.user_agent || "-",
          action: items?.action || "-",
          user_ip: items?.user_ip || "-",
        };

        newData.push(temp);
      });
    return newData;
  };
// jj
  return (
    <>
      <div style={{ marginTop: "20px" }} className="securityInnerDiv">
        <div className={`assetTable`}>
          <Table
            rowKey={(obj) => obj.keyId}
            columns={tableCol}
            dataSource={datax()}
            pagination={false}
            className="portfolioTable cursorInitial"
          />
          <CustomPagination
            pageNo={pageNo}
            limit={limit}
            total={totalActivity}
            onChange={(pageNo, pageSize) => {
              setPageNo(pageNo);
              setLimit(pageSize);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default LoginActivity;
