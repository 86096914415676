import React, { useEffect, useRef, useState } from "react";
import { Input, Row } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { buySellConvertTabsKey, convertType } from "../../../staticObjects";
import style from "./style.module.scss";
import CommonCurrFromTo from "./CommonCurrFromTo";
import CommonButton from "../../Ui/button/CommonButton";
import {
  exponentialToDecimalConvert,
  isObjHasValue,
  multiple0BeforeDecimal,
  regex,
  toOptionalFixed,
  uppercase,
} from "../../../helpers";
import { FocusWithIncreaseWidth } from "../../FocusAndIncreaseWidth";
import ConvertPreview from "./ConvertPreview";

const Convert = () => {
  const { t } = useTranslation();
  const { currenciesList, balanceList } = useSelector(
    (state) => state?.commonApiData
  );
  const amountRef = useRef();
  const [fromCurr, setFromCurr] = useState({});
  const [toCurr, setToCurr] = useState({});
  const [firstCurrObj, setFirstCurrObj] = useState({});
  const [secondCurrObj, setSecondCurrObj] = useState({});
  const [dollerAmt, setDollerAmt] = useState("");
  const [amountError, setAmountError] = useState("");
  const [fromAmt, setFromAmt] = useState(0);
  const [toAmt, setToAmt] = useState(0);
  const [convertPreview, setConvertPreview] = useState(false);
  const { from, to } = convertType;

  const filterSelectCurrObj = (value) => {
    return currenciesList?.filter((item) => item.id === value)[0];
  };

  useEffect(() => {
    if (balanceList.length) {
      setFromCurr(balanceList[0]);
      setToCurr(balanceList[1]);
    }
  }, [balanceList]);

  useEffect(() => {
    let firstObj = filterSelectCurrObj(fromCurr?.currency);
    let secondObj = filterSelectCurrObj(toCurr?.currency);
    setFirstCurrObj(firstObj);
    setSecondCurrObj(secondObj);
  }, [fromCurr, toCurr]);

  const handleChange = (e) => {
    let entered_val = e.target.value;
    entered_val = multiple0BeforeDecimal(entered_val);
    let re = regex(4, 10);
    if (entered_val === "" || re.test(entered_val)) {
      setDollerAmt(entered_val);
      convertValInCoin(entered_val, fromCurr, toCurr);
      handleValidation({ dollerAmt: entered_val }, fromCurr, firstCurrObj);
    }
  };

  const resetFields = () => {
    setAmountError("");
    setDollerAmt("");
    setFromAmt(0);
    setToAmt(0);
  };

  const cbFun = (obj, type) => {
    let tempFromCurr = { ...fromCurr };
    let tempToCurr = { ...toCurr };

    if (type === from && obj?.currency !== tempFromCurr?.currency) {
      if (obj?.currency === tempToCurr?.currency) {
        convertValInCoin(dollerAmt, obj, tempFromCurr);
        setToCurr(tempFromCurr);
      } else {
        convertValInCoin(dollerAmt, obj, tempToCurr);
      }
      resetFields();
      setFromCurr(obj);
    } else if (type === to && obj?.currency !== tempToCurr?.currency) {
      if (obj?.currency === tempFromCurr?.currency) {
        setFromCurr(tempToCurr);
        convertValInCoin(dollerAmt, tempToCurr, obj);
      } else {
        convertValInCoin(dollerAmt, tempFromCurr, obj);
      }
      resetFields();
      setToCurr(obj);
    } else if (type === "swapCurr") {
      setFromCurr(tempToCurr);
      setToCurr(tempFromCurr);
      resetFields();
    }
  };

  const convertValInCoin = (dollAmt, fromCurrency, toCurrency) => {
    let tempFrom = toOptionalFixed(
      Number(dollAmt) / Number(fromCurrency.currency_usdt),
      firstCurrObj?.precision ?? 2
    );
    let tempTo = toOptionalFixed(
      Number(dollAmt) / Number(toCurrency.currency_usdt),
      secondCurrObj?.precision ?? 2
    );

    setFromAmt(tempFrom);
    setToAmt(tempTo);
  };

  const handleValidation = (data, fromCurr, firstCurrObj) => {
    let isValidated = true;
    for (let key in data) {
      let value = Number(data[key]);
      switch (key) {
        case "dollerAmt":
          let minSwap = toOptionalFixed(
            Number(firstCurrObj?.swap_min_amount),
            4
          );

          let dollVal = toOptionalFixed(
            Number(fromCurr?.currency_usdt) * Number(fromCurr?.balance),
            4
          );
          if (value === 0) {
            setAmountError("Please enter an amount");
            isValidated = false;
          } else if (value < minSwap) {
            setAmountError(
              `Amount should be equal or greater than $${minSwap}`
            );
            isValidated = false;
          } else if (value > dollVal) {
            setAmountError(`Enter an amount not higher than available balance`);
            isValidated = false;
          } else {
            setAmountError("");
          }
          break;
        default:
      }
    }
    return isValidated;
  };

  const previewFun = (e) => {
    e.preventDefault();
    let res = handleValidation(
      { dollerAmt: dollerAmt },
      fromCurr,
      firstCurrObj
    );
    if (res) {
      setConvertPreview(true);
      amountRef?.current.blur();
    }
  };

  const convertSuccess = () => {
    resetFields();
    setConvertPreview(false);
    setTimeout(() => {
      amountRef?.current?.focus();
    }, 500);
  };

  const { currency_name, balance, currency_usdt, currency } = fromCurr;
  const dollVal = toOptionalFixed(Number(currency_usdt) * Number(balance), 4);

  const currBal = exponentialToDecimalConvert(
    toOptionalFixed(balance, firstCurrObj?.precision ?? 2)
  );

  return (
    <>
      <FocusWithIncreaseWidth ref={amountRef} />
      <form onSubmit={previewFun}>
        <div className={style.Buy}>
          <Row className={style.Buy_row}>
            <div className="inputSec ">
              <sup>$</sup>
              <Input
                value={dollerAmt}
                name="dollerAmt"
                ref={amountRef}
                onChange={handleChange}
                className="amountField"
              />
            </div>
          </Row>
          <div className="text-center">
            <span className="errorCls">{amountError}</span>
          </div>

          {Number(balance) === 0 && (
            <p className={`text ${style.Buy_text}`}>
              {t("don't have to convert", { currency: fromCurr.currency_name })}
            </p>
          )}

          <Link
            to=""
            className={"grey-bg"}
            onClick={(e) => {
              e.preventDefault();
              convertValInCoin(dollVal, fromCurr, toCurr);
              handleValidation({ dollerAmt: dollVal }, fromCurr, firstCurrObj);
              setDollerAmt(dollVal);
            }}
          >
            <span>
              <b>{t("buttons.convert_all")}</b>
            </span>
          </Link>

          <CommonCurrFromTo
            cbFun={cbFun}
            firstCurr={fromCurr}
            secondCurr={toCurr}
            fromAmt={exponentialToDecimalConvert(fromAmt)}
            toAmt={exponentialToDecimalConvert(toAmt)}
            type={buySellConvertTabsKey.convert}
          />

          <div className={style.Buy_btnMain}>
            <CommonButton
              htmlType="submit"
              title={`${
                Number(fromAmt) > 0
                  ? "Preview Convert"
                  : `Convert ${currency_name ?? ""}`
              } `}
              className="btn"
              disabled={Number(balance) === 0}
            />
          </div>
          <div className={style.Buy_amount}>
            <span>Avl Bal</span>
            <p>
              <span>
                {+currBal ? currBal : 0} {uppercase(currency)} ≈
              </span>
              <span> ${dollVal ? dollVal : 0} </span>
            </p>
          </div>
        </div>
      </form>

      {convertPreview && (
        <div className={style.positionedContent}>
          <ConvertPreview
            onClick={() => setConvertPreview(false)}
            firstCurr={fromCurr}
            secondCurr={toCurr}
            fromAmt={exponentialToDecimalConvert(fromAmt)}
            toAmt={exponentialToDecimalConvert(toAmt)}
            firstCurrObj={firstCurrObj}
            secondCurrObj={secondCurrObj}
            callBack={convertSuccess}
          />
        </div>
      )}
    </>
  );
};

export default Convert;
