import React, { useCallback, useEffect, useState } from "react";
import { Select, Form, Button } from "antd";
import style from "./style.module.scss";
import { ButtonCustom } from "../../../Ui/button/ButtonCustom";
import { StoreImages } from "../../../Storeimgaes/StoreImages";
import { useDispatch, useSelector } from "react-redux";
import {
  depositPost,
  fetchAllBank,
  fetchBankDetail,
  getUser,
  qpayPost,
  socialpayPost,
} from "../../../../redux/services";
import { useTranslation } from "react-i18next";
import Custom_input from "../../../Ui/customInput/Custom_input";
import { debouncing, multiple0BeforeDecimal, regex } from "../../../../helpers";
import { bankAmountReset } from "../../../../redux/feature";
import { QRCodeSVG } from "qrcode.react";

const BankDepositeTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { MNT } = StoreImages;
  const [selectedBank, setSelectedBank] = useState(4);
  const [selectedBankName, setSelectedBankName] = useState("Khan Bank");
  const [amount, setAmount] = useState("");
  const [errors, setErrors] = useState("");
  const { allBankList, payData, detailBankList } = useSelector(
    (state) => state?.sendReceive
  );
  const detailBank = detailBankList;
  const bankDetailList = detailBank?.length > 0 && detailBank[0];

  const barCode = payData?.qr_image;

  const bankNameDropdown =
    allBankList?.length > 0 &&
    allBankList?.map((item) => {
      return {
        value: item.id,
        label: item.bank_name,
      };
    });

  useEffect(() => {
    // dispatch(fetchAllBank());
    dispatch(getUser());
  }, []);

  useEffect(() => {
    if (selectedBankName === "Q-pay" || selectedBankName === "Social-pay") {
      dispatch(bankAmountReset());
    } else {
      // dispatch(
      //   fetchBankDetail({
      //     id: selectedBank,
      //   })
      // );
    }
    setAmount("");
  }, [selectedBank]);

  const listing = [
    { id: 1, title: "Bank Account", main: `${bankDetailList.account_number || "-"}` },
    {
      id: 2,
      title: "Account Name",
      main: `${bankDetailList.bank_account_holder_name || "-"}`,
    },
    {
      id: 3,
      title: "Receiver Bank",
      main: `${bankDetailList.bank_name || "-"}`,
    },
    {
      id: 4,
      title: "Transaction Value",
      main: (
        <span
          style={{ color: "#f0b90b" }}
        >{`${bankDetailList.bank_account_holder_phone_number || "-"} ${bankDetailList.bank_account_holder_registeration_id || "-"}`}</span>
      ),
    },
  ];

  const onChange = (value, label) => {
    let data = label.label;
    setSelectedBank(value);
    setSelectedBankName(data);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };
  const delayToCallApi = useCallback(
    debouncing((entered_val) => {
      selectedBankName === "Q-pay"
        ? dispatch(
          qpayPost({
            amount: entered_val,
          })
        )
        : dispatch(
          socialpayPost({
            amount: entered_val,
          })
        );
    }, 1000),
    [selectedBankName]
  );
  const handleChange = (e) => {
    let entered_val = e.target.value;
    let name = e.target.name;
    const amountRegex = regex(6, 10);

    if (name === "amount") {
      entered_val = multiple0BeforeDecimal(entered_val);
    }

    if (entered_val === "" || amountRegex.test(entered_val)) {
      setAmount(Number(entered_val));
    }

    if (entered_val !== "") {
      delayToCallApi(entered_val);
    }
  };
  const onSubmit = (type) => {
    let sendData = {
      currency: "mnt",
      bank_name: selectedBankName,
      id: selectedBank,
    };
    if (type === "withAmount") {
      sendData["amount"] = amount;
    }
    dispatch(depositPost(sendData));
  };
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <div className={style.bar}>
        <h4>
          <span>{t("BankDeposit.maintabs.Currency")}</span>
          <img src={MNT} alt="icon" />
          MNT
        </h4>
      </div>
      <div className={style.DepositContent}>
        <Form.Item
          label={t("BankDeposit.maintabs.ChooseBankAccountlabel")}
          name=""
        >
          <Select
            style={{ width: "100%" }}
            className="customSelect"
            showSearch
            placeholder={t("BankDeposit.maintabs.ChooseBankAccountlabel")}
            optionFilterProp="children"
            defaultValue={selectedBank}
            onChange={onChange}
            onSearch={onSearch}
            filterOption={filterOption}
            options={bankNameDropdown}
          />
        </Form.Item>
        {selectedBankName === "Q-pay" || selectedBankName === "Social-pay" ? (
          <>
            <div>
              <Custom_input
                label={`Amount`}
                placeholder="Enter Amount"
                name="amount"
                value={amount}
                onChange={handleChange}
                max={100}
                height={"50px"}
                customClass={"customInputWithdarw"}
              />
              {errors && (
                <span className={`errorCls ${style.errorText}`}>{errors}</span>
              )}
            </div>
            {barCode && (
              <div className={style.barCodeImage}>
                <img alt="QR Code" src={"data:image/png;base64," + barCode} />
              </div>
            )}
            {payData.qr_text && selectedBankName === "Social-pay" && (
              <div className={style.barCodeImage}>
                <QRCodeSVG
                  value={payData.qr_text}
                  height={"200px"}
                  width={"200px"}
                />
              </div>
            )}
          </>
        ) : (
          <>
            <ul className={style.DepositContent_bankList}>
              {listing.map((text) => (
                <li
                  key={text.id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>{text.title}</span>
                  <span>{text.main}</span>
                </li>
              ))}
            </ul>
          </>
        )}
        <p>{t("BankDeposit.maintabs.note")}</p>
        {/* {selectedBankName === "Q-pay" || selectedBankName === "Social-pay" ? (
          <ButtonCustom
            label={t("BankDeposit.maintabs.dbtn")}
            greenbtn
            disabled={amount !== "" && amount > 999 ? false : true}
            onClick={(tyoe) => onSubmit( tyoe= "withAmount" )}
          />
        ) : (
          <ButtonCustom
            label={t("BankDeposit.maintabs.dbtn")}
            greenbtn
            onClick={onSubmit}
          />
        )} */}
      </div>
    </>
  );
};

export default BankDepositeTab;
