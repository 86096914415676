import React, { useEffect, useState } from "react";
import { QRCodeSVG } from "qrcode.react";
import { StoreImages } from "../../Storeimgaes/StoreImages";
import CommonAssetNetworkList from "./commonAssetNetworkList";
import {
  depositWithdrawDropdownType,
  sendReceiveType,
} from "../../../staticObjects";
import style from "./style.module.scss";
import { useSelector } from "react-redux";
import { copytoClipBoard, isLevel3, uppercase } from "../../../helpers";
import { useTranslation } from "react-i18next";
import Identityverify from "../../Common/Identityverify";
import { useParams } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";
import ListLikeDropdown from "../../Common/listLikeDropdown";

const { copy } = StoreImages;
const { asset, network } = depositWithdrawDropdownType;

const Recived = (props) => {
  const currencyId = useParams();
  const { t } = useTranslation();
  const { address: depositAddress } = useSelector((state) => state.sendReceive);
  const { data } = useSelector((state) => state.user);
  const [balanceObjState, setBalanceObjState] = useState({});
  const [currentNetwork, setCurrentNetwork] = useState({});

  const initDivs = {
    assetDiv: false,
    networkDiv: false,
  };

  const [divStatus, setDivStatus] = useState(initDivs);
  const [lastAction, setLastAction] = useState(asset);

  useEffect(() => {
    setBalanceObjState(props.balanceObj);
  }, [props.balanceObj]);

  const cbFun = (obj, type) => {
    if (type === asset) {
      setBalanceObjState({ ...obj });
    } else if (type === network) {
      setCurrentNetwork({ ...obj });
    }
  };

  const { min_deposit_amount, deposit_fee } = currentNetwork;
  const currency = uppercase(balanceObjState?.currency);

  if (!isLevel3(data?.level)) {
    return <Identityverify cbOnClick={props.closeModal} />;
  }
  const { assetDiv, networkDiv } = divStatus;

  const openCloseDivsFun = (status, type) => {
    let tempDiv = { ...divStatus };
    if (!status) {
      setDivStatus(initDivs);
    } else {
      if (type === "asset") {
        tempDiv["assetDiv"] = true;
        tempDiv["networkDiv"] = false;
      } else if (type === network) {
        tempDiv["networkDiv"] = true;
        tempDiv["assetDiv"] = false;
      }
      setDivStatus({ ...tempDiv });
    }
  };

  const setAssetFun = (obj, type) => {
    if (type === asset) {
      cbFun(obj, type);
      setLastAction(type);
    }
  };

  return (
    <>
      <CommonAssetNetworkList
        className={`${style.deposit}`}
        balanceObj={balanceObjState}
        cbFun={cbFun}
        type={sendReceiveType.deposit}
        staticData={true}
      />

      <div className={style.recived}>
        <div className="text-center">
          {/* <QRCodeSVG value={depositAddress} height={"200px"} width={"200px"} /> */}
          <QRCodeSVG value={"TDFf5X9azSLcvo7YTrMGkx7teFWYnZtqBc"} height={"200px"} width={"200px"} />

        </div>
        <div className={style.recived_input}>
          <h6 className="black14 ">
            <span>{currency} address</span>
            <img
              className="curserPointer"
              src={copy}
              alt="icon"
              onClick={() => copytoClipBoard(depositAddress)}
            />
          </h6>
          {/* <p className="grey twelve">{depositAddress}</p> */}
          <p className="grey twelve">{"TDFf5X9azSLcvo7YTrMGkx7teFWYnZtqBc"}</p>

        </div>
        <h6 className="black14">Important</h6>
        <p className="grey twelve">
          {t("send&Receive.note1", {
            value: `${min_deposit_amount} ${currency}`,
          })}
        </p>
        {Number(deposit_fee) > 0 && (
          <>
            <p className="grey twelve">
              {t("send&Receive.note2", { value: `${deposit_fee}%` })}
            </p>
            <p className="grey twelve">
              {t("send&Receive.note7")} <br />
              <b> {t("send&Receive.note8", { currency: currency })}</b>
            </p>
          </>
        )}

        <p className="grey twelve">
          {t("send&Receive.note3", { currency: currency })}
        </p>
      </div>
    </>
  );
};

export default Recived;
