import React, { useState } from "react";
import { Button, Row, Col, Tabs } from "antd";
import style from "./style.module.scss";
import BankDepositeTab from "./BankDepositeTab";
import WithdrawTabs from "./WithdrawTabs";
import TabPane from "antd/lib/tabs/TabPane";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { StoreImages } from "../../../Storeimgaes/StoreImages";
import { isLevel3 } from "../../../../helpers";
import Identityverify from "../../../Common/Identityverify";
import { useSelector } from "react-redux";

const BanksDeposite = () => {
  const { t } = useTranslation();
  const { ArrowRightGreen } = StoreImages;
  const { data } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const cryptopage = () => {
    // Use navigate to go to the About page
    navigate("/cryoto_deposit");
  };
  const [selectedPane, setSelectedPane] = useState("deposit");

  const QA = [
    {
      id: 1,
      Question: <>{t("BankDeposit.fq.Q1")}</>,
      Ans: <>{t("BankDeposit.fq.ans1")}</>,
    },
    {
      id: 2,
      Question: <>{t("BankDeposit.fq.Q2")}</>,
      Ans: <>{t("BankDeposit.fq.ans2")}</>,
    },
    {
      id: 3,
      Question: <>{t("BankDeposit.fq.Q3")}</>,
      Ans: <>{t("BankDeposit.fq.ans3")}</>,
    },
  ];

  if (!isLevel3(data?.level)) {
    return <Identityverify />;
  }
  return (
    <div className="comman-padding">
      <div className={style.banner}>
        <div className={style.banner_content}>
          <h4>{t("BankDeposit.head")}</h4>
          <Button onClick={cryptopage}>
            {t("BankDeposit.btnText")}
            <img style={{ padding: "6px" }} src={ArrowRightGreen} alt="icon" />
          </Button>
        </div>
      </div>
      <div style={{ marginTop: "30px" }} className="dispositWithdrawTab">
        <Row gutter={[40, 40]}>
          <Col lg={12} className="cmnTabStyle">
            <div className="bank-deposite-draw-tabs">
              <Tabs
                className="open_AllOrderTab"
                defaultActiveKey="1"
                onChange={(value) => setSelectedPane(value)}
                activeKey={selectedPane}
              >
                <TabPane tab={<p>Deposit</p>} key="deposit">
                  {selectedPane === "deposit" && <BankDepositeTab />}
                </TabPane>
                <TabPane tab={<p>Withdraw</p>} key="withdraw">
                  {selectedPane === "withdraw" && <WithdrawTabs />}
                </TabPane>
              </Tabs>
            </div>
          </Col>
          <Col lg={12} className="cmnTabStyle">
            <div className={style.QApart}>
              <h4>{t("BankDeposit.fq.title")}</h4>
              {QA.map((text) => (
                <div className={style.QApart_content} key={text.id}>
                  <h5>{text.Question}</h5>
                  <p>{text.Ans}</p>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default BanksDeposite;
