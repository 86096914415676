import React, { useEffect, useRef, useState } from "react";
import { Input, message, Row, Tabs } from "antd";
import { Trans, useTranslation } from "react-i18next";
import CommonButton from "../../Ui/button/CommonButton";
import { StoreImages } from "../../Storeimgaes/StoreImages";
import CommonAssetNetworkList from "./commonAssetNetworkList";
import {
  depositWithdrawDropdownType,
  fieldType,
  initPhone,
  sendReceiveType,
} from "../../../staticObjects";
import {
  concatePhoneNo,
  customPhoneValidation,
  isLevel3,
  multiple0BeforeDecimal,
  regex,
  toOptionalFixed,
  truncateData,
  uppercase,
} from "../../../helpers";
import style from "./style.module.scss";
import { plusNum } from "../../../utils/formatter";
import { useDispatch, useSelector } from "react-redux";
import Custom_input from "../../Ui/customInput/Custom_input";
import AddressBook from "./addressBookModal";
import { createWithdraw, getOtpServicePeatio } from "../../../redux/services";
import GoogleAuthModal from "../../Common/Modals/GoogleAuthModal";
import { FocusWithIncreaseWidth } from "../../FocusAndIncreaseWidth";
import Identityverify from "../../Common/Identityverify";
import TabPane from "antd/lib/tabs/TabPane";
import { yupResolver } from "@hookform/resolvers/yup";
import { createAccountSchema } from "../../../Schema";
import { useForm } from "react-hook-form";
import CustomPhoneNoInput from "../../Ui/PhoneNoInput";
import { emailRegex } from "../../../Schema/untils";
import useCountdownSeconds from "../../../customHooks/useCountdownSeconds";
import { useLocation, useParams } from "react-router-dom";
const { normalInput } = fieldType;

const initData = {
  amount: "",
  address: "",
  otp: "",
  email: "",
};
const Send = (props) => {
  const { t } = useTranslation();
  const currencyId = useParams();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.user);
  const { currenciesList } = useSelector((state) => state.commonApiData);
  const [balanceObjState, setBalanceObjState] = useState({});
  const [currentNetwork, setCurrentNetwork] = useState({});
  const [currentCurrency, setCurrentCurrency] = useState({});
  const [withdrawData, setWithdrawData] = useState({ ...initData });
  const [errors, setErrors] = useState({ ...initData });
  const [getAmount, setGetAmount] = useState(0.0);
  const [disbaleBtn, setDisableBtn] = useState(false);
  const [enable2fa, setEnable2fa] = useState(false);
  const [selectedPane, setSelectedPane] = useState("withdrawalAddress");
  const [selectedPane2, setSelectedPane2] = useState("email");
  const [addressBookModal, setAddressBookModal] = useState(false);
  const [phone, setPhone] = useState({ ...initPhone });
  const [firstClick, setSirstClick] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [otpClicked, setOtpClicked] = useState(0);
  const [seconds, secondsFun, clearTimer] = useCountdownSeconds(60);
  const [resendStatus, setResendStatus] = useState(false);

  const amountRef = useRef();
  const { balanceObj, closeModal } = props;
  const { exchange, AddressIcon } = StoreImages;
  const { asset, network } = depositWithdrawDropdownType;
  const { currency_name, currency, balance } = balanceObjState;
  const { min_withdraw_amount, withdraw_fee, blockchain_key } = currentNetwork;
  const currInCap = currency && uppercase(currency);
  const is2FA = data.otp;

  useEffect(() => {
    if (firstClick) {
      const res = customPhoneValidation(phone);
      setPhoneError(res.error);
    }
  }, [firstClick]);

  useEffect(() => {
    if (!seconds) {
      setResendStatus(false);
    }
  }, [seconds]);

  const sendOtp = async () => {
    setResendStatus(true);
    clearTimer();
    let res = await dispatch(getOtpServicePeatio({ otpClicked }));
    if (res.payload.status) {
      secondsFun();
      // State update after secondsFun responds
      setTimeout(() => {
        setOtpClicked(otpClicked + 1);
      }, 1000);
    } else {
      setResendStatus(false);
    }
  };

  const filterSelectCurrObj = (value) => {
    return currenciesList?.filter((item) => item.id === value)[0];
  };

  const { control } = useForm({
    resolver: yupResolver(createAccountSchema),
  });

  const handleValidation = (data) => {
    let isValidated = true;
    let copyerror = { ...errors };

    for (let key in data) {
      let value = data[key].toString().trim();

      switch (key) {
        case "amount":
          setGetAmount(0);
          let floatedval = parseFloat(value);
          let willGetBal = floatedval - parseFloat(withdraw_fee);
          let minWithdrawAmt = plusNum(min_withdraw_amount, withdraw_fee);

          let truncateGetAmount = truncateData(
            willGetBal,
            currentCurrency?.precision
          );
          let finalGetAmount =
            Number(truncateGetAmount) < 0 ? 0 : truncateGetAmount;

          if (value === "") {
            copyerror[key] = "Please enter a valid amount";
            isValidated = false;
          } else if (floatedval > balance) {
            copyerror[key] =
              "Enter an amount not higher than available balance";
            isValidated = false;
          } else if (floatedval < minWithdrawAmt) {
            copyerror[
              key
            ] = `Amount to send should be at least ${minWithdrawAmt}`;
            isValidated = false;
          } else {
            copyerror[key] = "";
            setGetAmount(finalGetAmount);
          }
          break;

        case "address":
          if (selectedPane === "withdrawalAddress") {
            if (value === "") {
              // copyerror[key] = "Please enter address";
              isValidated = false;
            } else {
              copyerror[key] = "";
            }
          }
          break;

        case "email":
          if (
            selectedPane !== "withdrawalAddress" &&
            selectedPane2 === "email"
          ) {
            if (value.trim() === "") {
              copyerror[key] = "Please enter Email";
              isValidated = false;
            } else if (!emailRegex.test(value)) {
              copyerror[key] = "Please enter a valid Email";
              isValidated = false;
            } else {
              copyerror[key] = ""; // Clear any existing error message
            }
          }
          break;

        case "otp":
          if (value === "" && selectedPane !== "withdrawalAddress") {
            copyerror[key] = "Please enter code";
            isValidated = false;
          } else {
            copyerror[key] = "";
          }
          break;

        default:
          break;
      }
    }

    setErrors({ ...copyerror });
    return isValidated;
  };

  const handleChange = (e) => {
    let entered_val = e.target.value;
    let name = e.target.name;

    // Define your regex patterns
    const amountRegex = regex(6, 10);
    const addressRegex = /^[A-Za-z0-9]*$/;
    const otpRegex = /^[0-9]*$/;

    // Determine which regex pattern to use based on the input name
    const re =
      name === "amount"
        ? amountRegex
        : name === "address"
          ? addressRegex
          : otpRegex;
    if (name === "amount") {
      entered_val = multiple0BeforeDecimal(entered_val);
    }

    if (entered_val === "" || (re.test(entered_val) && name !== "email")) {
      handleValidation({ [name]: entered_val }); // Perform validation
      setWithdrawData({ ...withdrawData, [name]: entered_val }); // Update state
    } else if (name === "email") {
      handleValidation({ [name]: entered_val }); // Perform validation
      setWithdrawData({ ...withdrawData, [name]: entered_val });
    }
  };

  useEffect(() => {
    setBalanceObjState(balanceObj);
    setCurrentCurrency({ ...filterSelectCurrObj(balanceObj.currency) });
  }, [balanceObj]);

  const resetFields = () => {
    setWithdrawData({ ...initData });
    setErrors({ ...initData });
    setGetAmount(0);
    setSirstClick(false);
    setPhone({ ...initPhone });
    secondsFun(0);
    setOtpClicked(0);
    setResendStatus(false);
    clearTimer();
  };

  const cbFun = (obj, type) => {
    if (type === asset) {
      if (obj.currency !== balanceObjState.currency) {
        setBalanceObjState({ ...obj });
        setCurrentCurrency({ ...filterSelectCurrObj(obj.currency) });
        resetFields();
      }
    } else if (type === network) {
      if (obj?.blockchain_key !== currentNetwork.blockchain_key) {
        setCurrentNetwork({ ...obj });
        resetFields();
      }
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setSirstClick(true);
    if (!is2FA && otpClicked === 0) {
      setErrors({ ...initData, otp: selectedPane === "withdrawalAddress" ? "" : "Please get a Withdraw Otp code first " });
      return;
    }
    let res = handleValidation(withdrawData);
    let phoneValid;
    let phoneNo;
    if (selectedPane !== "withdrawalAddress" && selectedPane2 === "phone") {

      phoneValid = customPhoneValidation(phone);
      phoneNo = concatePhoneNo(phone);
    }

    if (phoneValid !== undefined && !phoneValid.isValid) return;

    if (res) {
      let amount = withdrawData.amount;
      if (amount.endsWith(".")) {
        amount = amount + "0";
      }


      setDisableBtn(true);
      const sendData = {
        amount: amount,
        currency: currency,
        blockchain_key: blockchain_key,
      };
      if (is2FA) {
        sendData["otp"] = withdrawData.otp;
      } else {
        sendData["code"] = withdrawData.otp;
      }
      if (withdrawData.address !== "") {
        sendData["rid"] = withdrawData.address;
      }
      if (withdrawData.email !== "") {
        sendData["email"] = withdrawData.email;
      }
      if (phoneNo !== "") {
        sendData["phone_number"] = phoneNo;
      }
      let data = await dispatch(createWithdraw(sendData));
      if (data.payload.status) {
        resetFields();
      }
      setDisableBtn(false);
    }
  };

  const closeModalIfOnSameRoute = () => {
    setAddressBookModal(false);
    closeModal();
  };

  if (!isLevel3(data?.level)) {
    return <Identityverify cbOnClick={closeModal} />;
  }

  const commanProp = { errors, control };
  return (
    <>
      <FocusWithIncreaseWidth ref={amountRef} />
      <div className={style.Buy}>
        <form onSubmit={onSubmit}>
          <Row className={style.Buy_row}>
            <div className="inputSec ">
              {/* <sup>$</sup> */}
              <Input
                name="amount"
                value={withdrawData.amount}
                onChange={handleChange}
                ref={amountRef}
                className="amountField"
              />
            </div>
            <p>
              <span>
                <img src={exchange} alt="icon" />
              </span>
              <br />
              {currInCap}
            </p>
            <span className={`errorCls ${style.errorText}`}>
              {errors.amount}
            </span>
          </Row>

          <p className={`text ${style.Buy_text}`}>
            Avl Bal: {toOptionalFixed(balance, currentCurrency?.precision)}
          </p>

          <CommonAssetNetworkList
            className={`${style.withdraw}`}
            cbFun={cbFun}
            balanceObj={balanceObjState}
            type={sendReceiveType.withdraw}
          // staticData={true}
          />

          <Tabs
            className="withdrawTab1"
            defaultActiveKey="1"
            onChange={(value) => {
              setSelectedPane(value);
              setWithdrawData({
                ...withdrawData,
                otp: "",
                email: "",
                address: "",
              });
              setPhone({ ...initPhone });
              setErrors({ ...initData });
              setPhoneError("");
            }}
            activeKey={selectedPane}
          >
            <TabPane tab={<p>Address</p>} key="withdrawalAddress">
              <div className={style.withdrawalAddress}>
                <h6>Important</h6>
                <p>
                  {t("send&Receive.note4", {
                    value: `${plusNum(
                      min_withdraw_amount,
                      withdraw_fee
                    )} ${currInCap}`,
                  })}
                </p>
                <p>
                  {t("send&Receive.note9", {
                    value: `${plusNum(
                      min_withdraw_amount,
                      withdraw_fee
                    )} ${currInCap}`,
                  })}
                </p>
                <p>
                  <Trans i18nKey={"send&Receive.note5"}>
                    <span />
                  </Trans>
                </p>
                <p>{t("send&Receive.note6")}</p>
                <div className={style.withdrawalAddress_inputSec}>
                  <div className={style.addressbookIcon}>
                    <img
                      src={AddressIcon}
                      alt="Address Icon"
                      width={19}
                      height={19}
                      onClick={() =>
                        setAddressBookModal(true)
                      }
                    />
                  </div>
                  <Custom_input
                    label={`${currInCap} Address`}
                    placeholder="Enter Address"
                    name="address"
                    value={withdrawData.address}
                    onChange={handleChange}
                    max={100}
                    height={"50px"}
                    customClass={"customInputWithdarw"}
                  />
                  <span
                    className={`errorCls ${style.bottomO} ${style.errorTextInput}`}
                  >
                    {errors.address}
                  </span>
                </div>
              </div>
            </TabPane>
            <TabPane tab={<p>Antcheck User</p>} key="coinHubUser">
              <Tabs
                className="withdrawTab2"
                defaultActiveKey="1"
                onChange={(value) => {
                  setSelectedPane2(value);
                  setWithdrawData({ ...withdrawData, email: "" });
                  setPhone({ ...initPhone });
                  setErrors({ ...errors, email: "" });
                  setPhoneError(null);
                }}
                activeKey={selectedPane2}
              >
                <TabPane tab={<p>Email</p>} key="email">
                  <Custom_input
                    name="email"
                    value={withdrawData.email}
                    placeholder={"Email"}
                    onChange={handleChange}
                    label={"Enter Your Email"}
                  />
                  {errors.email && (
                    <p
                      className={`errorCls ${style.bottomO} ${style.errorTextInput}`}
                    >
                      {errors.email}
                    </p>
                  )}
                </TabPane>
                <TabPane tab={<p>Phone</p>} key="phone">
                  <CustomPhoneNoInput
                    enableSearch
                    value={phone}
                    label={
                      <span className={style.labelTextSpan}>Phone Number</span>
                    }
                    onChange={(obj) => {
                      if (firstClick) {
                        const res = customPhoneValidation(obj);
                        setPhoneError(res.error);
                      }
                      setPhone(obj);
                    }}
                  />
                  <span className="errorCls">{t(phoneError)}</span>
                </TabPane>
              </Tabs>
            </TabPane>
          </Tabs>
          <div
            className={`${style.withdrawalAddress_inputSec} ${style.lastChild}`}
          >
            {is2FA ? (
              <Custom_input
                label={`Enter 2FA code`}
                placeholder="Enter code"
                name="otp"
                value={withdrawData.otp}
                onChange={handleChange}
                max={6}
                height={"50px"}
                customClass={"customInputWithdarw"}

              />
            ) : (
              <div
                className={`${style.input_righttext} input_righttext passPadding`}
              >
                <label style={{ marginBottom: "10px", display: "block" }}>
                  {" "}
                  Enter email otp code
                </label>
                <Custom_input
                  name="otp"
                  value={withdrawData.otp}
                  placeholder="Enter Code"
                  onChange={handleChange}
                  onlycurrency={`${otpClicked
                    ? seconds
                      ? `${seconds} Seconds`
                      : `Resend OTP`
                    : "Get Code"
                    }`}
                  rightTextClass={resendStatus && !seconds ? "otp_clicked" : ""}
                  {...(!resendStatus && selectedPane === "coinHubUser" && {
                    rightTextClick: () => {
                      sendOtp();
                    },
                  })}
                  disable={selectedPane === "withdrawalAddress" ? true : false}
                />

                {/* <CustomError errors={errors} name="otp" /> */}
              </div>
            )}

            <span
              className={`errorCls ${style.bottomO} ${style.errorTextInput}`}
            >
              {errors.otp}
            </span>
          </div>
          {selectedPane === "withdrawalAddress" && (
            <>
              <p>
                Transaction Fee:{" "}
                <span>
                  {withdraw_fee} {currInCap}
                </span>
              </p>
              <p>
                Received Amount:
                <span> {getAmount}</span>
              </p>
            </>
          )}
          <div className={style.Buy_btnMain}>
            <CommonButton
              title="Send"
              className="btn"
              onClick={onSubmit}
              disabled={disbaleBtn}
              btnType="submit"
            />
          </div>
        </form>
      </div>

      {addressBookModal && (
        <AddressBook
          addressBook={addressBookModal}
          onCancel={() => setAddressBookModal(false)}
          closeModalIfOnSameRoute={closeModalIfOnSameRoute}
          cbFun={(text) => {
            handleValidation({ address: text });
            setWithdrawData({ ...withdrawData, address: text });
          }}
          currCurrency={currency}
        />
      )}
      {enable2fa && (
        <GoogleAuthModal
          open={enable2fa}
          onCancel={() => setEnable2fa(false)}
        />
      )}
    </>
  );
};

export default Send;
