import { createSlice } from "@reduxjs/toolkit";
import {
  getBalanceUsdt,
  getFutureSetting,
  getMarketTickers,
  getMarkets,
  getOrderBook,
  getOrderHistory,
  getOrderHistoryInnerData,
  getTrades,
  getTradingRules,
} from "../../services";
import { klineArrayToObject } from "../../../helpers/ranger";
import {
  getCloneData,
  insertOrUpdate,
  insertOrUpdateHistory,
} from "../../../helpers";

const initialState = {
  orderBookList: [],
  tradeList: [],
  tradesCount: 0,
  marketList: [],
  currentMarket: {},
  marketTickers: {},
  mMarketTickers: {
    funding_rate: 0,
    index_price: 0,
    m_price: 0,
    nx_funding_time: 0,
  },
  currentMarketId: "",
  baseCurrency: {},
  success: false,
  error: false,
  loading: false,
  kline: {
    data: [],
    last: undefined,
    loading: false,
    marketId: undefined,
    period: undefined,
  },
  klinePeriod: "15m",
  askCurrentValue: undefined,
  bidCurrentValue: "",
  orderHistory: [],
  positionHistory: [],
  allOrders: [],
  allOrdersCount: 0,
  tradingRules: [],
  currTab: "Open Orders",
  localExData: {
    currentLTP: 0,
  },
  balanceUpdatedUSDT: 0,
  futureSettingData: {},
  klineAPIData: [],
  orderHistoryInnerData: [],
};

export const exchangeSlice = createSlice({
  name: "exchangeSlice",
  initialState: initialState,
  reducers: {
    setLocalExData: (state, action) => {
      state.localExData = { ...state.localExData, ...action.payload };
    },
    setCurrentMarket: (state, action) => {
      state.currentMarket = action.payload.currentMarket;
      state.currentMarketId = action.payload.currentMarketId;
    },
    setBaseCurrency: (state, action) => {
      state.baseCurrency = action.payload.baseCurrency;
    },
    setMarketTickers: (state, action) => {
      state.marketTickers = action.payload.marketTickers;
    },
    setMMarketTickers: (state, action) => {
      state.mMarketTickers = action.payload.mMarketTickers;
    },
    setOrderBook: (state, action) => {
      state.orderBookList = action.payload.orderBook;
    },
    setTrades: (state, action) => {
      let trades = getCloneData(state.tradeList);
      trades.splice(0, 1);
      trades.push(action?.payload?.trade);
      state.tradeList = trades;
    },
    resetCurrentMarketId: (state, action) => {
      state.currentMarketId = "";
    },
    setKlinePeriod: (state, action) => {
      state.klinePeriod = action.payload;
    },
    setKlineData: (state, action) => {
      state.kline.data = [];
      state.kline.last = klineArrayToObject(action.payload.last);
      state.kline.marketId = action.payload.marketId;
      state.kline.period = action.payload.period;
    },

    setKlineAPIData: (state, action) => {
      state.klineAPIData = action.payload;
    },
    setBalaneeUpdateUSDT: (state, action) => {
      state.balanceUpdatedUSDT = action.payload.balanceUpdatedUSDT;
    },
    resetKlineData: (state, action) => {
      state.kline.data = [];
      state.kline.last = undefined;
      state.kline.marketId = undefined;
      state.kline.period = undefined;
    },
    setAskVal: (state, action) => {
      state.askCurrentValue = action?.payload;
    },
    setBidVal: (state, action) => {
      state.bidCurrentValue = action?.payload;
    },
    resetBidVal: (state, action) => {
      state.bidCurrentValue = "";
    },
    setSuccess: (state, action) => {
      state.success = action?.payload;
    },
    setExchangeState: (state, action) => {
      state[action?.payload?.key] = action?.payload?.data;
    },
    updateOpenOrder: (state, action) => {
      let openOrderList = getCloneData(state.orderHistory);
      let newData = insertOrUpdate(openOrderList, action.payload);
      state.orderHistory = newData;
    },
    updateAllOrder: (state, action) => {
      let allOrderList = getCloneData(state.allOrders);
      const arr = insertOrUpdateHistory(allOrderList, action.payload);
      state.allOrders = arr;
    },
    updatePositionAllOrder: (state, action) => {
      state.positionHistory = action.payload;
    },
    setCurrentTabForLocal: (state, action) => {
      state.currTab = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrderBook.pending, (state) => {
        state.error = false;
      })
      .addCase(getOrderBook.fulfilled, (state, action) => {
        state.loading = false;
        state.orderBookList = action.payload.list;
      })
      .addCase(getOrderBook.rejected, (state, action) => {
        state.error = true;
      })
      .addCase(getTrades.pending, (state) => {
        state.error = false;
        state.tradeList = [];
      })
      .addCase(getTrades.fulfilled, (state, action) => {
        state.loading = false;
        state.tradeList = action?.payload?.list;
        state.tradesCount = action?.payload?.total;
      })
      .addCase(getTrades.rejected, (state, action) => {
        state.error = true;
      })
      .addCase(getMarkets.pending, (state) => {
        state.marketList = [];
        state.error = false;
      })
      .addCase(getMarkets.fulfilled, (state, action) => {
        state.loading = false;
        state.marketList = action?.payload?.list;
      })
      .addCase(getMarkets.rejected, (state, action) => {
        state.error = true;
      })
      .addCase(getMarketTickers.pending, (state) => {
        state.error = false;
      })
      .addCase(getMarketTickers.fulfilled, (state, action) => {
        state.loading = false;
        state.marketTickers = action.payload.list;
      })
      .addCase(getMarketTickers.rejected, (state, action) => {
        state.error = true;
      })

      .addCase(getOrderHistory.pending, (state) => {
        state.error = false;
        state.orderHistory = [];
        state.allOrders = [];
        // state.positionHistory = [];
      })
      .addCase(getOrderHistory.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.callFrom === "setting") {
          state.allOrders = action?.payload?.list;
          state.allOrdersCount = action?.payload?.total ?? 0;
        } else {
          if (action?.payload?.state === "wait") {
            state.orderHistory = action?.payload?.list;
          } else if (action?.payload?.state === "fill") {
            state.positionHistory = action?.payload?.list;
          } else {
            state.allOrders = action?.payload?.list;
            state.allOrdersCount = action?.payload?.total ?? 0;
          }
        }
      })
      .addCase(getOrderHistory.rejected, (state, action) => {
        state.error = true;
      })

      .addCase(getTradingRules.pending, (state) => {
        state.error = false;
      })
      .addCase(getTradingRules.fulfilled, (state, action) => {
        state.loading = false;
        state.tradingRules = action?.payload?.list;
      })
      .addCase(getTradingRules.rejected, (state, action) => {
        state.error = true;
      })

      .addCase(getBalanceUsdt.pending, (state) => {
        state.error = false;
      })
      .addCase(getBalanceUsdt.fulfilled, (state, action) => {
        state.loading = false;
        state.balanceUpdatedUSDT = action.payload.res;
      })
      .addCase(getBalanceUsdt.rejected, (state, action) => {
        state.error = true;
      })
      .addCase(getFutureSetting.pending, (state) => {
        state.error = false;
      })
      .addCase(getFutureSetting.fulfilled, (state, action) => {
        state.loading = false;
        state.futureSettingData = action.payload.res;
      })
      .addCase(getFutureSetting.rejected, (state, action) => {
        state.error = true;
      })

      .addCase(getOrderHistoryInnerData.pending, (state) => {
        state.error = false;
      })
      .addCase(getOrderHistoryInnerData.fulfilled, (state, action) => {
        state.orderHistoryInnerData = [
          ...state.orderHistoryInnerData,
          action.payload.res,
        ];
      })
      .addCase(getOrderHistoryInnerData.rejected, (state, action) => {
        state.error = true;
      });
  },
});

export const {
  setCurrentMarket,
  setBaseCurrency,
  setMarketTickers,
  setMMarketTickers,
  setOrderBook,
  setTrades,
  resetCurrentMarketId,
  setKlineData,
  setBalaneeUpdateUSDT,
  setKlinePeriod,
  setAskVal,
  setBidVal,
  resetBidVal,
  resetKlineData,
  setSuccess,
  setExchangeState,
  updateOpenOrder,
  updateAllOrder,
  setCurrentTabForLocal,
  setLocalExData,
  updatePositionAllOrder,
  setKlineAPIData,
} = exchangeSlice.actions;
export default exchangeSlice.reducer;
