import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { generateSocketURI, streamsBuilder } from "../helpers/ranger";
import { rangerUrl } from "../api";
import {
  announceListBySocket,
  insertRewardRangerList,
  notiListBySocket,
} from "../redux/feature";
import {
  setBalaneeUpdateUSDT,
  setKlineData,
  setMMarketTickers,
  setMarketTickers,
  setOrderBook,
  setTrades,
  updateAllOrder,
  updateOpenOrder,
  updatePositionAllOrder,
} from "../redux/feature/exchange/exchanges.slice";
import { isAuthenticated } from "../redux/selector";
import { notiStreams } from "../staticObjects";

function Ranger() {
  const dispatch = useDispatch();
  const isAuthenticate = useSelector((state) => isAuthenticated(state));
  const currTabExchange = useSelector((state) => state.exchange.currTab);
  const { tradingType } = useSelector((state) => state.commonApiData);
  const { Order, Withdraw, Deposit, Member, Announcement } = notiStreams;
  const rangerBaseUrl = isAuthenticate
    ? `${rangerUrl()}/private`
    : `${rangerUrl()}/public`;
  const currentMarketId = useSelector(
    (state) => state?.exchange?.currentMarketId
  );
  const currentPeriod = useSelector((state) => state?.exchange?.klinePeriod);
  const streams = streamsBuilder(currentMarketId, currentPeriod, tradingType);

  useEffect(() => {
    if (currentMarketId !== undefined) {
      const ws = new WebSocket(generateSocketURI(rangerBaseUrl, streams));
      ws.onmessage = function (event) {
        const json = JSON.parse(event.data);
        for (const routingKey in json) {
          switch (routingKey) {
            case "positions":
              dispatch(updatePositionAllOrder(json[routingKey]));
              break;
            case "global.tickers":
            case "global.f_tickers":
              dispatch(insertRewardRangerList(json[routingKey]));
              dispatch(setMarketTickers({ marketTickers: json[routingKey] }));
              break;
            case `${currentMarketId}.update`:
            case `f_${currentMarketId}.update`:
              dispatch(setOrderBook({ orderBook: json[routingKey] }));
              break;

            case `f_${currentMarketId}.mp`:
              dispatch(
                setMMarketTickers({ mMarketTickers: json[routingKey].mp[0] })
              );
              break;

            case `${currentMarketId}.trades`:
            case `f_${currentMarketId}.trades`:
              dispatch(setTrades({ trade: json[routingKey]?.trades[0] }));
              break;

            case `balance_update`:
              dispatch(
                setBalaneeUpdateUSDT({ balanceUpdatedUSDT: json[routingKey] })
              );
              break;

            case `${currentMarketId}.kline-${currentPeriod}`:
            case `f_${currentMarketId}.kline-${currentPeriod}`:
              dispatch(
                setKlineData({
                  data: [],
                  last: json[routingKey],
                  loading: false,
                  marketId: currentMarketId,
                  period: currentPeriod,
                })
              );
              break;
            case `order`:
            case `f_order`:
              if (json[routingKey].state) {
                if (
                  currTabExchange === "Trade History" &&
                  ["wait", "pending"].includes(json[routingKey].state)
                ) {
                } else {
                  dispatch(updateOpenOrder(json[routingKey]));
                  dispatch(updateAllOrder(json[routingKey]));
                }
              }
              break;

            // notification by socket start
            case Order:
            case Withdraw:
            case Deposit:
            case Member:
              dispatch(notiListBySocket(json[routingKey]));
              break;
            // notification by socket End
            case Announcement:
              dispatch(announceListBySocket(json[routingKey]));
              break;
            default:
              break;
          }
        }
      };
      return () => ws.close();
    }
  }, [currentPeriod, currentMarketId, currTabExchange]);
}
export default withTranslation()(Ranger);
