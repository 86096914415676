import CoinDetail from "../Components/Pages/CoinDetail/CoinDetail.jsx";
import {
  Dashboard,
  Login,
  ChangePassword,
  ResetPassword,
  Earn,
  Swap,
  CreateAcount,
  VerifyEmail,
  Identity,
  Portfolio,
  bank,
  VerifyIdentity,
  Setting,
  Order,
  Exchange,
  Referral,
  ListedCoins,
  ReferralViewAll,
  RewardViewAll,
  EmailConfirmation,
  ViewAll,
  Landing,
  AdressManagement,
  ViewAllHistory,
  Applications,
  CoinListingIndex,
  Transactions,
  RetailKyc,
  CorporateKyc,
  ConvertMNG,
  ConvertHistory,
  LogoutCompo,
} from "./lazyRoute";
import DeviceManagement from "../Components/Pages/Setting/DeviceManagement.jsx";
import AccountActivity from "../Components/Pages/Setting/AccountActivity.jsx";
import {
  sidebarRouteObj,
  publicRouteObj,
  primaryRoutes,
} from "../staticObjects/routing";
import BanksDeposite from "../Components/Pages/Funds/Banks/BanksDeposite.jsx";
import CryptoDesposite from "../Components/Pages/Funds/Crypto/CryptoDesposite.jsx";

// Public routing array

const {
  login,
  changePassword,
  resetPassword,
  createAccount,
  emailVerify,
  emailConfirmation,
  identity,
  verifyIdentity,
} = publicRouteObj;

export const publicRoutesData = [
  {
    path: primaryRoutes.landing,
    component: <Landing />,
  },
  {
    // path: login,
    // component: <Login />,
  },
  {
    path: `${changePassword}/:id`,
    component: <ChangePassword />,
  },
  {
    path: resetPassword,
    component: <ResetPassword />,
  },
  {
    path: createAccount,
    component: <CreateAcount />,
  },
  {
    path: emailVerify,
    component: <VerifyEmail />,
  },
  {
    path: `${emailConfirmation}/:id`,
    component: <EmailConfirmation />,
  },
  {
    path: identity,
    component: <Identity />,
  },
  {
    path: verifyIdentity,
    component: <VerifyIdentity />,
  },
];

// Private routing array

const {
  home,
  port,
  swap,
  advTrade,
  earn,
  p2p,
  coinListing,
  referral,
  listedCoins,
  transactions,
  cryoto_deposit,
  bank_deposit,
} = sidebarRouteObj;
const {
  overview,
  setting,
  deviceManagement,
  accountActivity,
  kycRetail,
  kycCorporate,
  order,
  notfication,
  viewAll,
  viewHistory,
  addressManagement,
  intro,
  introWithoutId,
  referralViewAll,
  rewardViewAll,
  convertMNG,
  convertHistory,
  authLanding,
  logout,
} = primaryRoutes;

export const privateRoutesData = [
  {
    path: ``,
    component: <Dashboard />,
  },
  {
    path: logout,
    component: <LogoutCompo />,
  },
  {
    path: authLanding,
    component: <Landing />,
  },
  {
    path: `${home}`,
    component: <Dashboard />,
  },
  {
    path: `${port}`,
    component: <Portfolio />,
  },
  // =======================deposite====================
  {
    path: `${cryoto_deposit}`,
    component: <CryptoDesposite />,
  },
  {
    path: `${cryoto_deposit}/:id`,
    component: <CryptoDesposite />,
  },
  {
    path: `${bank_deposit}`,
    component: <BanksDeposite />,
  },
  // ==============================================
  {
    path: `${swap}`,
    component: <Swap />,
  },
  {
    path: `${advTrade}`,
    component: <Exchange />,
  },
  {
    path: `${advTrade}/:marketId`,
    component: <Exchange />,
  },
  {
    path: `${earn}`,
    component: <Earn />,
  },
  {
    path: `${transactions}`,
    component: <Transactions />,
  },
  // {
  //   path: `${coinListing}`,
  //   component: <Applications />,
  // },
  // {
  //   path: `${listedCoins}`,
  //   component: <ListedCoins />,
  // },
  // {
  //   path: `${intro}`,
  //   component: <CoinListingIndex />,
  // },
  // {
  //   path: `${introWithoutId}`,
  //   component: <CoinListingIndex />,
  // },
  {
    path: `${referral}`,
    component: <Referral />,
  },
  {
    path: `${referralViewAll}`,
    component: <ReferralViewAll />,
  },
  {
    path: `${rewardViewAll}`,
    component: <RewardViewAll />,
  },

  {
    path: `${overview}/:id`,
    component: <CoinDetail />,
  },
  {
    path: `${setting}`,
    component: <Setting />,
  },
  {
    path: `${deviceManagement}`,
    component: <DeviceManagement />,
  },
  {
    path: `${accountActivity}`,
    component: <AccountActivity />,
  },
  {
    path: `${kycRetail}`,
    component: <RetailKyc />,
  },
  {
    path: `${kycCorporate}`,
    component: <CorporateKyc />,
  },

  {
    path: `${order}`,
    component: <Order />,
  },
  {
    path: `${viewAll}`,
    component: <ViewAll />,
  },
  {
    path: `${viewHistory}`,
    component: <ViewAllHistory />,
  },
  {
    path: `${convertMNG}`,
    component: <ConvertMNG />,
  },
  {
    path: `${convertHistory}`,
    component: <ConvertHistory />,
  },
  { path: `${addressManagement}`, component: <AdressManagement /> },
];
