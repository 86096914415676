import React, { useState } from "react";
import { Button, Modal } from "antd";
import CommonRenderFields from "../../../Ui/commonRenderFields";
import {
  addBankObj,
  addBankObjDefault,
  bankList,
  fieldType,
} from "../../../../staticObjects";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { addBankSchema } from "../../../../Schema";
import CommonButton from "../../../Ui/button/CommonButton";
import style from "./style.module.scss";
import { useDispatch } from "react-redux";
import { addBankbeneficiary } from "../../../../redux/services";
import { useTranslation } from "react-i18next";

const Addbank = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { normalInput, selectInput } = fieldType;
  const { chooseAccount, accountNo, accountName } = addBankObj;
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isValid: formIsvalid },
    getValues,
    clearErrors,
    resetField,
    reset,
  } = useForm({
    resolver: yupResolver(addBankSchema),
    defaultValues: addBankObjDefault, // Set default value for piNationality
  });
  const onSubmit = async (data) => {
    const { chooseAccount, accountNo, accountName } = data;
    const sendData = {
      bank_name: chooseAccount,
      bank_account_holder_name: accountName,
      account_number: accountNo,
    };

    let res = await dispatch(addBankbeneficiary(sendData));
    if (res?.payload?.status) {
      setIsModalOpen(false);
      reset();
    }
  };
  const commanProp = { errors, control };

  return (
    <>
      <div className={style.withdrawBtn}>
        <Button
          style={{ color: "#f0b90b", textAlign: "right" }}
          type="link"
          onClick={showModal}
        >
          {/* {t("BankDeposit.addBankmodal.AddBankAccount")} */}
          Add Bank Account
        </Button>
      </div>
      <Modal
        className={`${style.addBeneficiary}  headerModals buySellModa`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        footer={null}
      >
        <div className={style.DepositContent}>
          <h5
            style={{ textAlign: "center", fontSize: "16px", fontWeight: "600" }}
          >
            Add Bank Address
          </h5>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={style.beneficiaryForm}
          >
            <CommonRenderFields
              {...commanProp}
              type={selectInput}
              name={chooseAccount}
              // defaultValue="А" 
              label={"Choose Bank Account"}
              placeholder="Choose Bank Account"
              dropOption={bankList}
            />

            <CommonRenderFields
              {...commanProp}
              name={accountNo}
              placeholder={"123456987"}
              label={"Account Number *"}
              type={normalInput}
            />
            <CommonRenderFields
              {...commanProp}
              name={accountName}
              placeholder={"Munkh-Erdene"}
              label={"Account Name"}
              type={normalInput}
            />
            {/* <CommonButton btn_custom title="Verify" htmlType="submit" /> */}
            <CommonButton btn_custom title="Verify" />

          </form>
        </div>
      </Modal>
    </>
  );
};

export default Addbank;
