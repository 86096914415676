import React, { lazy } from "react";

export const Mainlayout = lazy(() =>
  import("../Components/Layout/Mainlayout/Mainlayout")
);
export const Dashboard = lazy(() => import("../Components/Pages/Dashboard"));
export const LogoutCompo = lazy(() => import("../Components/Pages/Logout"));
export const PageNotFound = lazy(() =>
  import("../Components/Pages/PageNotFound/PageNotFound")
);
export const Login = lazy(() => import("../Components/Pages/Login"));
export const ChangePassword = lazy(() =>
  import("../Components/Pages/Login/PasswordSetting/ChangePassword")
);
export const ResetPassword = lazy(() =>
  import("../Components/Pages/Login/PasswordSetting/ResetPassword")
);
export const CreateAcount = lazy(() =>
  import("../Components/Pages/SignUp/CreateAcount")
);
export const VerifyEmail = lazy(() =>
  import("../Components/Pages/SignUp/VerifyEmail")
);
export const Verifiaction = lazy(() =>
  import("../Components/Pages/SignUp/Verifiaction")
);
export const VerificationCode = lazy(() =>
  import("../Components/Pages/SignUp/VerificationCode")
);
export const Identity = lazy(() =>
  import("../Components/Pages/SignUp/Identity")
);
export const VerifyIdentity = lazy(() =>
  import("../Components/Pages/SignUp/VerifyIdentity")
);
export const Portfolio = lazy(() =>
  import("../Components/Pages/Portfolio/Portfolio")
);
export const Earn = lazy(() => import("../Components/Pages/Earn"));
export const Swap = lazy(() => import("../Components/Pages/Swap/Swap"));
export const Setting = lazy(() =>
  import("../Components/Pages/Setting/Setting")
);
export const DeviceManagement = lazy(() =>
  import("../Components/Pages/Setting/DeviceManagement")
);
export const Referral = lazy(() =>
  import("../Components/Pages/Referral/Referral")
);
export const ListedCoins = lazy(() =>
  import("../Components/Pages/CoinListing/ListedCoins/ListedCoins")
);
export const ReferralViewAll = lazy(() =>
  import("../Components/Pages/Referral/ReferralViewAll")
);
export const RewardViewAll = lazy(() =>
  import("../Components/Pages/Referral/RewardViewAll")
);
export const Order = lazy(() =>
  import("../Components/Pages/ExchangePages/Order/Order")
);

export const Exchange = lazy(() =>
  import("../Components/Pages/ExchangePages/Exchange.jsx")
);

export const EmailConfirmation = lazy(() =>
  import("../Components/Pages/EmailConfirmation/index.jsx")
);

export const ViewAll = lazy(() =>
  import("../Components/Pages/Earn/ViewAll.jsx")
);

export const Landing = lazy(() =>
  import("../Components/Pages/Landing/index.jsx")
);
export const AdressManagement = lazy(() =>
  import("../Components/Pages/DepositWithdraw/AddressMangement.jsx")
);

export const ViewAllHistory = lazy(() =>
  import("../Components/Pages/Earn/ViewAllHistory.jsx")
);

export const Applications = lazy(() =>
  import("../Components/Pages/CoinListing/Applications/Applications.jsx")
);
export const CoinListingIndex = lazy(() =>
  import("../Components/Pages/CoinListing/Applications/Index.jsx")
);
export const Transactions = lazy(() =>
  import("../Components/Pages/Transactions/index.jsx")
);
export const RetailKyc = lazy(() =>
  import("../Components/Pages/Setting/RetailKyc.jsx")
);

export const CorporateKyc = lazy(() =>
  import("../Components/Pages/Setting/CorporateKyc.jsx")
);
export const ConvertMNG = lazy(() =>
  import("../Components/Pages/Portfolio/ConvertMNG.jsx")
);
export const ConvertHistory = lazy(() =>
  import("../Components/Pages/Portfolio/ConvertHistory.jsx")
);

export const BanksDeposite = lazy(() =>
  import("../Components/Pages/Funds/Banks/BanksDeposite.jsx")
);
