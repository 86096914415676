import React, { useCallback, useEffect, useState } from "react";
import { Select, Form } from "antd";
import style from "./style.module.scss";
import { StoreImages } from "../../../Storeimgaes/StoreImages";
import Addbank from "./Addbank";
import { ButtonCustom } from "../../../Ui/button/ButtonCustom";
import CommonModal from "../../../CommonModal";
import { useTranslation } from "react-i18next";
import {
  fetchBankbeneficiary,
  getBalance,
  getCurrencies,
  getOtpServicePeatio,
  getUser,
  withdrawsPost,
} from "../../../../redux/services";
import { useDispatch, useSelector } from "react-redux";
import Custom_input from "../../../Ui/customInput/Custom_input";
import {
  debouncing,
  isLevel3,
  multiple0BeforeDecimal,
  regex,
  uppercase,
} from "../../../../helpers";
import GoogleAuthModal from "../../../Common/Modals/GoogleAuthModal";
import Identityverify from "../../../Common/Identityverify";
import useCountdownSeconds from "../../../../customHooks/useCountdownSeconds";

const initData = {
  amount: "",
  bankAccount: "",
  otp: "",
};

const WithdrawTabs = () => {
  // Redux setup
  const dispatch = useDispatch();
  const { MNT } = StoreImages;
  const { t } = useTranslation();

  // Redux selectors
  const { bankbeneficiaryList } = useSelector((state) => state?.sendReceive);
  const { data } = useSelector((state) => state.user);
  const { currenciesList, balanceList } = useSelector(
    (state) => state.commonApiData
  );

  // Component state
  const [receivedAmount, setReceivedAmount] = useState("0.0");
  const [openModal, setOpenModal] = useState(false);
  const [withdrawData, setWithdrawData] = useState({ ...initData });
  const [errors, setErrors] = useState({ ...initData });
  const [otpClicked, setOtpClicked] = useState(0);
  const [seconds, secondsFun, clearTimer] = useCountdownSeconds(60);
  const [resendStatus, setResendStatus] = useState(false);

  let is2Fa = data.otp;
  let userBls = balanceList.length > 0 ? balanceList[0]?.balance : 0.0;

  useEffect(() => {
    // dispatch(fetchBankbeneficiary());
    // dispatch(getCurrencies({ id: "mnt" }));
    // dispatch(getBalance({ search: { currency_code: "mnt" } }));
  }, []);
  useEffect(() => {
    if (!seconds) {
      setResendStatus(false);
    }
  }, [seconds]);
  const sendOtp = async () => {
    setResendStatus(true);
    clearTimer();

    let res = await dispatch(getOtpServicePeatio({ otpClicked }));
    if (res.payload.status) {
      secondsFun();
      // State update after secondsFun responds
      setTimeout(() => {
        setOtpClicked(otpClicked + 1);
      }, 1000);
    } else {
      setResendStatus(false);
    }
  };

  const bankNameDropdown =
    bankbeneficiaryList?.length > 0 &&
    bankbeneficiaryList?.map((item) => {
      <option>Choose Bank Account</option>;
      return {
        value: item.id,
        label: item.bank_name,
      };
    });
  const onChange = (value) => {
    setWithdrawData({ ...withdrawData, bankAccount: value });
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };
  let minWithdrawAmt = currenciesList?.swap_min_amount;
  const handleValidation = (data) => {
    let isValidated = true;
    let copyerror = { ...errors };
    for (let key in data) {
      let value = data[key]?.toString().trim();
      switch (key) {
        case "amount":
          // setGetAmount(0);
          let floatedval = parseFloat(value);
          // let willGetBal = parseFloat(Number(value)) - parseFloat(Number(withdraw_fee));
          // let minWithdrawAmt = plusNum(min_withdraw_amount, withdraw_fee);

          // let truncateGetAmount = truncateData(
          //   willGetBal,
          //   currentCurrency?.precision
          // );

          // let finalGetAmount =
          //   Number(truncateGetAmount) < 0 ? 0 : truncateGetAmount;
          if (value === "") {
            copyerror[key] = "Please enter a valid amount";
            isValidated = false;
          } else if (floatedval > userBls) {
            copyerror[key] =
              "Enter an amount not higher than available balance";
            isValidated = false;
          } else if (floatedval < minWithdrawAmt) {
            copyerror[
              key
            ] = `Amount to send should be atleast ${minWithdrawAmt}`;
            isValidated = false;
          } else {
            copyerror[key] = "";
            // setGetAmount(finalGetAmount);
          }
          break;
        case "bankAccount":
          if (value === "") {
            copyerror[key] = "Please choose bank Account";
            isValidated = false;
          } else {
            copyerror[key] = "";
          }
          break;
        case "otp":
          if (value === "") {
            copyerror[key] = "Please enter code";
            isValidated = false;
          } else {
            copyerror[key] = "";
          }
          break;

        default:
      }
    }
    setErrors({ ...copyerror });
    return isValidated;
  };

  const onSubmitFun = async (e) => {
    e.preventDefault();
    let res = handleValidation(withdrawData);
    if (!is2Fa && otpClicked === 0) {
      setErrors({ ...initData, otp: "Please get a Withdraw Otp code first " });
      return;
    }
    if (res) {
      let amount = withdrawData.amount;
      if (amount.endsWith(".")) {
        amount = amount + "0";
      }
      const sendData = {
        bank_detail_id: withdrawData.bankAccount,
        amount: amount,
        currency: "mnt",
        // blockchain_key: blockchain_key,
      };
      if (is2Fa) {
        sendData["otp"] = withdrawData.otp;
      } else {
        sendData["code"] = withdrawData.otp;
      }
      let data = await dispatch(withdrawsPost(sendData));
      if (data.payload.status) {
        setWithdrawData({ ...initData });
        clearTimer();
        setErrors({ ...initData });
        setOtpClicked(0);
        setResendStatus(false);
      }
    }
  };
  const finalWithdraw = (e) => {
    onSubmitFun(e);
    // !is2Fa ? setOpenModal(true) : onSubmitFun(e);
  };

  const delayToCallApi = useCallback(
    debouncing((entered_val) => {
      if (entered_val > 0 && currenciesList?.swap_fees) {
        setReceivedAmount(
          Number(entered_val) - Number(currenciesList?.swap_fees)
        );
      } else {
        setReceivedAmount("0.0");
      }
    }, 1000),
    [receivedAmount, currenciesList]
  );

  const handleChange = (e) => {
    let entered_val = e.target.value;
    let name = e.target.name;
    const amountRegex = regex(6, 10);
    const addessRegex = /^[A-Za-z0-9]*$/;
    const optRegex = /^[0-9]*$/;

    const re =
      name === "amount"
        ? amountRegex
        : name === "address"
          ? addessRegex
          : optRegex;

    if (name === "amount") {
      entered_val = multiple0BeforeDecimal(entered_val);
    }

    if (entered_val === "" || re.test(entered_val)) {
      handleValidation({ [name]: entered_val });
      setWithdrawData({ ...withdrawData, [name]: entered_val });
    }
    delayToCallApi(entered_val);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  if (!isLevel3(data?.level)) {
    return <Identityverify />;
  }
  return (
    <div className={`${style.withdrawPage} withdrawPage`}>
      <div className={style.bar}>
        <h4>
          <span>{t("BankDeposit.maintabs.Currency")}</span>
          <img src={MNT} alt="icon" />
          MNT
        </h4>
      </div>
      <div className={style.DepositContent}>
        <Form.Item
          label={t("BankDeposit.maintabs.ChooseBankAccountlabel")}
          name=""
        >
          <Select
            label={"Withdrawal Amount"}
            className="customSelect"
            showSearch
            value={withdrawData.bankAccount || undefined}
            placeholder="Choose Bank Account"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={filterOption}
            options={bankNameDropdown}
          />

          {errors.bankAccount && (
            <span className={`errorCls ${style.errorText}`}>
              {errors.bankAccount}
            </span>
          )}
        </Form.Item>
        <Addbank />

        <div className={style.amountWithcurncy}>
          <Custom_input
            label={`Withdrawal Amount`}
            placeholder="Enter Amount"
            name="amount"
            value={withdrawData.amount}
            onChange={handleChange}
            max={100}
            height={"50px"}
            customClass={"customInputWithdarw"}
          />
          <span className={style.crncyName}>
            {uppercase(currenciesList?.id)}
          </span>
        </div>
        {errors.amount && (
          <span className={`errorCls ${style.errorText}`}>{errors.amount}</span>
        )}
        <div className={style.withdrawInfo}>
          <p className={style.avlBls}>
            Available Balance: {userBls} {uppercase(currenciesList?.id)}
          </p>
          <p>
            Transaction Fee:{" "}
            <b>
              {currenciesList?.swap_fees} {uppercase(currenciesList?.id)}
            </b>
          </p>
          <p>
            Received Amount:{" "}
            <b>
              {receivedAmount} {uppercase(currenciesList?.id)}
            </b>
          </p>
          <p>
            Minimum Withdrawal:{" "}
            <b>
              {minWithdrawAmt ? minWithdrawAmt : "0.0"}{" "}
              {uppercase(currenciesList?.id)}
            </b>
          </p>
          <p>
            Daily Withdrawal limit:{" "}
            <b>
              {currenciesList?.daily_limit} {uppercase(currenciesList?.id)}
            </b>
          </p>
        </div>

        <div
          className={`${style.withdrawalAddress_inputSec} ${style.lastChild}`}
        >
          {is2Fa ? (
            <>
              <Custom_input
                label={`Enter 2FA code`}
                placeholder="Enter code"
                name="otp"
                value={withdrawData.otp}
                onChange={handleChange}
                max={6}
                height={"50px"}
                customClass={"customInputWithdarw"}
              />
            </>
          ) : (
            <div
              className={`${style.input_righttext} input_righttext passPadding`}
            >
              <Custom_input
                name="otp"
                value={withdrawData.otp}
                placeholder="Enter Code"
                onChange={handleChange}
                onlycurrency={`${otpClicked
                  ? seconds
                    ? `${seconds} Seconds`
                    : `Resend OTP`
                  : "Get Code"
                  }`}
                rightTextClass={resendStatus && !seconds ? "otp_clicked" : ""}
                {...(!resendStatus && {
                  rightTextClick: () => {
                    // sendOtp();
                  },
                })}
              />

              {/* <CustomError errors={errors} name="otp" /> */}
            </div>
          )}
          <span className={`errorCls ${style.bottomO} ${style.errorTextInput}`}>
            {errors.otp}
          </span>
        </div>

        {/* <CommonModal>dflkjljfkslkjf</CommonModal> */}

        <ButtonCustom
          // label={is2Fa ? t("BankDeposit.maintabs.Wbtn") : t("withdraw.enable")}
          label={t("BankDeposit.maintabs.Wbtn")}
          greenbtn
        // onClick={(e) => finalWithdraw(e)}
        />
      </div>
      {openModal && (
        <GoogleAuthModal
          open={openModal}
          onCancel={() => setOpenModal(false)}
        />
      )}
    </div>
  );
};

export default WithdrawTabs;
