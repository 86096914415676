import React, { useEffect, useState } from "react";
import style from "../Banks/style.module.scss";
import { StoreImages } from "../../../Storeimgaes/StoreImages";
import { Button, Row, Col, Tabs } from "antd";
import CryptoDepositeTab from "./CryptoDepositeTab";
import { useNavigate, useParams } from "react-router-dom";
import Recived from "../../DepositWithdraw/Recived";
import TabPane from "antd/lib/tabs/TabPane";
import Send from "../../DepositWithdraw/Send";
import { filterCurrWithNetwork, lowercase } from "../../../../helpers";
import { getBalance, getCurrencies } from "../../../../redux/services";
import { useDispatch, useSelector } from "react-redux";

const CryptoDesposite = () => {
  const { MNT, ArrowRightGreen } = StoreImages;
  const currencyId = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedPane, setSelectedPane] = useState("deposit");
  const { currenciesList } = useSelector((state) => state?.commonApiData);
  const [currentObj, setCurrentObj] = useState({});
  useEffect(() => {
    actionsFun();
  }, []);
  console.log("currenciesList", currenciesList);

  const filterCurrencyByType = (balList, currList) => {
    let filterArr = balList?.filter(
      (val, idx) => val?.currency_type === "coin"
    );


    if (filterArr.length) {
      let newArray = filterCurrWithNetwork(currList, balList);
      let findCurr = newArray?.filter(
        (val, idx) => val?.currency === lowercase(currencyId.id)
      );
      if (currencyId.id) {

        setCurrentObj(findCurr[0]);
      } else {
        setCurrentObj({ ...newArray[0] });
      }
    }
  };
  const actionsFun = async () => {
    // onClose();
    let res = await dispatch(getBalance());
    let res1 = await dispatch(getCurrencies());
    if (res.payload.status && res1.payload.status) {
      filterCurrencyByType(res.payload.list, res1.payload.list);
    }
  };
  const bankpage = () => {
    // Use navigate to go to the About page
    navigate("/bank_deposit");
  };

  const QA = [
    {
      id: 1,
      Question:
        "Q1. What is the business case for introducing (or migrating parts to) blockchain?",
      Ans: "Reduced audit cost, lesser marketing budget (most of which is spent in faking numbers), reduced accounting cost, reduced compliance cost, faster business growth, better financial reconciliation",
    },
    {
      id: 2,
      Question: "Q2. Should blockchain always be public?",
      Ans: "No. It can be access controlled.",
    },
    {
      id: 3,
      Question: "Q3. How can we prevent wallets from being hacked?",
      Ans: "You may implement multiple factor authentication. At the end of the day, what happens if a user posts his credit card pin on his social media? It's the same thing.",
    },
  ];

  const items = [
    {
      key: "1",
      label: "Deposit",
      children: <CryptoDepositeTab />,
    },
    {
      key: "2",
      label: "Withdraw",
      // children: <CryptoWithDrawTab />,
    },
  ];

  return (
    <>
      {/* <div className={style.bar}>
        <h4>
          <span>Currency</span>
          <img src={MNT} alt="icon" />
          MNT
        </h4>
      </div> */}
      <div className="comman-padding">
        <div className={style.banner}>
          <div className={style.banner_content}>
            <h4>Crypto Deposit</h4>
            {/* <Button onClick={bankpage}>
              Bank Deposit
              <img
                style={{ padding: "6px" }}
                src={ArrowRightGreen}
                alt="icon"
              />
            </Button> */}
          </div>
        </div>
        <div style={{ marginTop: "30px" }} className="dispositWithdrawTab">
          <Row gutter={[40, 40]}>
            <Col xl={12} xs={24} className="cmnTabStyle">
              <div className="bank-deposite-draw-tabs">
                <Tabs
                  className="open_AllOrderTab"
                  defaultActiveKey="1"
                  onChange={(value) => setSelectedPane(value)}
                  activeKey={selectedPane}
                >
                  <TabPane tab={<p>Deposit</p>} key="deposit">
                    {selectedPane === "deposit" && (
                      <Recived balanceObj={currentObj} />
                    )}
                  </TabPane>
                  <TabPane tab={<p>Withdraw</p>} key="withdraw">
                    {selectedPane === "withdraw" && (
                      <Send
                        balanceObj={currentObj}
                      // closeModal={sendReceiveCloseModal}
                      />
                    )}
                  </TabPane>
                </Tabs>
              </div>
            </Col>
            <Col
              xl={12}
              xs={24}
              className="cmnTabStyle"
              style={{ display: "flex", height: "auto" }}
            >
              <div className={style.QApart}>
                <h4>FAQ</h4>
                {QA.map((text) => (
                  <div className={style.QApart_content} key={text.id}>
                    <h5>{text.Question}</h5>
                    <p>{text.Ans}</p>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default CryptoDesposite;
